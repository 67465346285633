@import '~@which/seatbelt/src/styles/styles';

.headerSearchWrapper {
  margin: 0 21px;

  @include sb-respond-to(medium-up) {
    margin: 0;
  }

  .content {
    > div[class*="PISearchBox_wrapper"] > div[class*="PISearchBox_inputWrapper"] {
      max-width: none;
    }
  }
}
