@import '~@which/seatbelt/src/styles/styles';

.checkButtonFilter {
  width: 318px;
  padding: 0 $sb-spacing-l;

  @include sb-respond-to(large) {
    padding: 0;
  }

  &Header {
    font-size: $sb-font-size-19;
  }

  .checkButtonWrapper {
    display: flex;
    align-items: center;
  }

  .buttonWrapper {
    > :not(:last-child) {
      margin-right: $sb-spacing-s;
      margin-bottom: $sb-spacing-s;
    }
  }

  &ShowMoreFilters {
    margin-top: $sb-spacing-s;

    button svg {
      margin-right: $sb-spacing-xs;
    }
  }
}
