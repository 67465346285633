@import '~@which/seatbelt/src/styles/styles';

.dropdownFilter {
  position: relative;
  padding: 0 $sb-spacing-l;

  @include sb-respond-to(large) {
    padding: 0;
  }

  &Header {
    font-size: $sb-font-size-19;
    margin-bottom: $sb-spacing-m;
  }

  &Title {
    margin-bottom: $sb-spacing-xs;
  }

  &Wrapper {
    margin-bottom: $sb-spacing-s;
    position: relative;
    width: 100%;

    @include sb-respond-to(large) {
      width: 97%;
    }
  }

  [class*='CustomDropdown__dropDownWrapper'] {
    max-width: 100%;
    position: absolute;
    z-index: 10;
    margin: 0;

    @include sb-respond-to(large) {
      margin: 0;
    }
  }

  //SB-TODO - double underscore > single underscore
  [class*='CustomDropdown_dropDownWrapper'] {
    max-width: 100%;
    position: absolute;
    z-index: 10;
    margin: 0;

    @include sb-respond-to(large) {
      margin: 0;
    }
  }

  .selectedContainer {
    div {
      border-right: solid 1px #ccc;
    }

    svg {
      justify-self: center;
    }

    @include sb-respond-to(small) {
      div {
        padding: 0;
        margin-left: $sb-spacing-m;
        margin-right: $sb-spacing-m;
      }
    }
  }
}
