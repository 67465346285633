@import '~@which/seatbelt/src/styles/styles';

.searchBody {
  position: relative;
  width: 100%;
  margin: $sb-spacing-m 0 $sb-spacing-xl;

  @include sb-respond-to(medium-up) {
    width: 77%;
    margin: 50px 0 0 0;
  }

  @include sb-respond-to(medium-down) {
    width: 100%;
  }
}
