@import '~@which/seatbelt/src/styles/styles';

.piSearchResults {
  display: flex;
  flex-direction: column;
  row-gap: $sb-spacing-l;
  margin-bottom: $sb-spacing-l;

  @include sb-respond-to(medium-up) {
    margin-bottom: $sb-spacing-2xl;
  }

  &Card {
    margin: 0 0 $sb-spacing-2xl;

    @include sb-respond-to(medium-up) {
      margin: 0 0 $sb-spacing-l;
    }
  }
}
