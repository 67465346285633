@import '~@which/seatbelt/src/styles/styles';

.checkboxFilter {
  width: 318px;
  padding: 0 $sb-spacing-l;
  > :not(:last-child) {
    margin-bottom: $sb-spacing-l;
  }

  @include sb-respond-to(large) {
    padding: 0;
  }

  .checkboxWrapper {
    display: flex;
    padding-left: $sb-spacing-s;

    &Label {
      margin-left: $sb-spacing-s;
      font-size: $sb-font-size-16;
      cursor: pointer;

      span {
        color: #676767;
        font-size: $sb-font-size-16;
      }
    }
  }
}
