@import '~@which/seatbelt/src/styles/styles';

.appliedFilters {
  padding: 0 0 21px;

  .flexWrapper {
    align-items: center;
    display: flex;
  }

  .clearAllFilters {
    display: block;
    margin-left: $sb-spacing-m;
  }

  .tagWrapper {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    margin-top: $sb-spacing-s;
    padding: 0;

    &FilterTag {
      flex-shrink: 0;
    }

    > li > button {
      color: sb-color('sb-color-black-100');
      margin-right: $sb-spacing-m;
      margin-top: $sb-spacing-m;

      &:not(:last-of-type) {
        margin-right: $sb-spacing-m;
      }
    }
  }
}
