@import '~@which/seatbelt/src/styles/styles';

.searchListHeader {
  display: flex;
}

.srOnly {
  @include sb-visually-hidden;
}

.resultsInHeader {
  display: none;

  @include sb-respond-to(large) {
    display: block;
    padding-right: 15px;
    flex: 0 1 50%;
  }
}

.tagWrapper {
  margin: $sb-spacing-s
    $sb-spacing-l;

  @include sb-respond-to(large) {
    margin: $sb-spacing-s 0;
  }
}

.authorDropdown {
  margin-bottom: $sb-spacing-m;
}

.authors {
  @include sb-respond-to(medium-up) {
    margin-bottom: $sb-spacing-3xl;
  }
  margin-bottom: $sb-spacing-xl;
}
