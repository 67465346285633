@import '~@which/seatbelt/src/styles/styles';

.emphasis {
  font-weight: bold;
}

.count {
  margin-bottom: $sb-spacing-l;

  @include sb-respond-to(medium-up) {
    padding-top: 7px;
  }

  @include sb-respond-to(large) {
    display: none;
    margin-bottom: 19px;
  }

  @include sb-respond-to(xlarge) {
    width: 590px;
  }
}

.resultCount {
  @include sb-respond-to(large) {
    display: block;
    margin-right: $sb-spacing-l;
  }

  @include sb-respond-to(xlarge) {
    align-self: flex-start;
  }
}
