@import '~@which/seatbelt/src/styles/styles';

.searchListHeaderWrapper {
  position: relative;

  @include sb-respond-to(small) {
    margin-left: -($sb-spacing-l);
    margin-right: -($sb-spacing-l);
  }

  @include sb-respond-to(large) {
    top: 0;
  }
}

.searchListHeader {
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  margin-bottom: $sb-spacing-l;

  @include sb-respond-to(small) {
    padding: 0 $sb-spacing-m 0 0;
  }

  @include sb-respond-to(large) {
    height: auto;
    align-items: flex-start;
  }
}

.isSticky {
  &.searchListHeaderWrapper {
    // acts as a shim for the sticky filters which are taken out of the page flow
    // this prevents page content from jumping up the page
    @include sb-respond-to(medium-down) {
      padding-bottom: 80px;
    }
  }

  .searchListHeader {
    padding: 0 21px;

    @include sb-respond-to(medium-down) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: sb-color('sb-color-white');
      margin: 0;
      box-shadow: 2px 6px 6px -2px rgba(0, 0, 0, 0.2);
    }

    @include sb-respond-to(medium) {
      padding-left: 43px;
      padding-right: 43px;

      a {
        margin: 0;
      }
    }
  }
}
